import { v4 as uuidv4 } from 'uuid';
const id = uuidv4();
export const setIdDevice = () => {
  const checkIdDevice = Boolean(localStorage.getItem('idDevice') || '');
  if (!checkIdDevice) {
    localStorage.setItem('idDevice', id.toUpperCase());
  }
  return localStorage.getItem('idDevice') || '';
};

export const removeIdDevice = () => {
  localStorage.removeItem('idDevice');
};

export const setValuePhone = (data) => {
  if (typeof data === 'object') {
    localStorage.setItem('valuePhone', JSON.stringify(data));
  }
};

export const getValuePhone = () => {
  const data = localStorage.getItem('valuePhone');
  if (data) {
    return JSON.parse(data);
  }
};
