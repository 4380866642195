import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    signOut() {
      return initialState;
    },
    loadUser(state, action) {
      state.action = action.payload.isAuthenticated;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const authState = (state) => state.auth;
export const authSelector = {
  userInfo: createSelector(authState, (auth) => auth.userInfo),
  loadUser: createSelector(authState, (auth) => auth.loadUser),
};
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
