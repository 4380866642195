import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import { appSelector } from '../../redux/slice/AppSlice';
import './loading.css';

const LoadingComponent = ({ loading }) => {
  if (loading)
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  else return null;
};

const mapStateToProps = (state) => ({
  loading: appSelector.loading(state),
});

export default connect(mapStateToProps, null)(LoadingComponent);
