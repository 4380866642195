import { combineReducers } from 'redux';

// ## Generator Reducer Imports
import { appReducer } from './slice/AppSlice';
import { authReducer } from './slice/AuthSlice';
import { progressReducer } from './slice/ProgressSlice';

export default combineReducers({
  app: appReducer,
  progress: progressReducer,
  auth: authReducer,
});
