import apiConfig, { jsonLog } from 'src/config/api.config';

export const assignShopCode = async (data, phoneNumber) => {
  try {
    const dataRes = await apiConfig.post(`/shop-code`, {
      jsonData: {
        shopcode: data,
        isdn: phoneNumber,
      },
    });
    if (!dataRes) {
      throw new Error('Giữ só không thành công');
    }
    return dataRes.data;
  } catch (error) {
    throw error;
  }
};
