import Helmet from '@components/Helmet/Helmet';
import LoadingComponent from '@components/Loading/Loading.component';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import History from './pages/History/History';

const PageOne = lazy(() => import('./pages/PageOne/PageOne'));

function App() {
  return (
    <Suspense fallback={<></>}>
      <Helmet title="Saymee"></Helmet>
      <BrowserRouter>
        <LoadingComponent />
        <Routes>
          <Route path="/" element={<PageOne />} />
          <Route path="/history" element={<History />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
