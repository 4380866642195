import React from 'react';
import phaohoa from '../../assets/images/phaohoa.png';
import closeButton from '../../assets/images/close-button.png';
import git2 from '../../assets/images/gift2.png';
import sad from '../../assets/images/khongtrung.png';
import baobinh from '../../assets/images/baobinh.png';
import bocap from '../../assets/images/bocap.png';
import cugiai from '../../assets/images/cugiai.png';
import kimnguu from '../../assets/images/kimnguu.png';
import maket from '../../assets/images/maket.png';
import nhanma from '../../assets/images/nhanma.png';
import songngu from '../../assets/images/songngu.png';
import songtu from '../../assets/images/songtu.png';
import sutu from '../../assets/images/sutu.png';
import thienbinh from '../../assets/images/thienbinh.png';
import xunu from '../../assets/images/xunu.png';
import bachduong from '../../assets/images/bachduong.png';
// import shadows from '@material-ui/core/styles/shadows';
const ModalConfirm = ({
  setIsShowModal,
  error,
  header,
  body,
  remove,
  handleDelete,
  inConfirm,
  imgIcon = 'sad',
}) => {
  return (
    <div className="modalWheel-container">
      {!error ? (
        <div className="modalWheel-phaohoa">
          <img src={phaohoa} alt="" className="modalWheel-phaohoa__img" />
        </div>
      ) : (
        ''
      )}
      <div className="modalWheel-main">
        <div className="modalWheel-close" onClick={() => setIsShowModal(false)}>
          <img src={closeButton} alt="" className="modalWheel-close__img" />
        </div>
        <div className="modalWheel-image">
          {error ? (
            <>
              {imgIcon === 'sad' ? (
                <img src={sad} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'BAOBINH' ? (
                <img src={baobinh} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'BOCAP' ? (
                <img src={bocap} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'CUGIAI' ? (
                <img src={cugiai} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'KIMNGUU' ? (
                <img src={kimnguu} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'MAKET' ? (
                <img src={maket} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'SONGNGU' ? (
                <img src={songngu} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'NHANMA' ? (
                <img src={nhanma} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'SONGTU' ? (
                <img src={songtu} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'SUTU' ? (
                <img src={sutu} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'THIENBINH' ? (
                <img src={thienbinh} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'XUNU' ? (
                <img src={xunu} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
              {imgIcon === 'BACHDUONG' ? (
                <img src={bachduong} alt="" className="modalWheel-img" />
              ) : (
                ''
              )}
            </>
          ) : (
            <div className="modalWheel-icon">🥳</div>
          )}
        </div>
        <div>
          <div className="modalWheel-content">{header}</div>
        </div>

        <div className="modalWheel-noti">{body}</div>
        <div className="modalWheel-gift">
          <img src={git2} alt="" className="modalWheel-gift__img" />
        </div>
        {remove ? (
          <></>
        ) : (
          <button
            className="modalWheel-button"
            onClick={() => setIsShowModal(false)}>
            Tiếp tục
          </button>
        )}
        {remove &&
          (inConfirm ? (
            ''
          ) : (
            <>
              <div className="modalWheel__confirm">
                <div
                  onClick={handleDelete}
                  className="btn btn-submit"
                  style={{ fontSize: '1.2rem', minWidth: '90px' }}>
                  Đồng ý
                </div>
                <div
                  onClick={() => setIsShowModal(false)}
                  className="btn btn-delete "
                  style={{ fontSize: '1.2rem', minWidth: '90px' }}>
                  Huỷ
                </div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default ModalConfirm;
