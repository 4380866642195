import React from 'react';
import phaohoa from '../../assets/images/phaohoa.png';
import closeButton from '../../assets/images/close-button.png';
import git2 from '../../assets/images/gift2.png';
import sad from '../../assets/images/khongtrung.png';
const ModalConfirmSuccess = ({
  setIsShowModal,
  header,
  body,
  handleSuccess,
  error,
  isConfirm,
}) => {
  return (
    <div className="modalWheel-container">
      <div className="modalWheel-main">
        <div className="modalWheel-close" onClick={() => setIsShowModal(false)}>
          <img src={closeButton} alt="" className="modalWheel-close__img" />
        </div>
        <div className="modalWheel-image">
          <div className="modalWheel-icon">🥳</div>
        </div>
        <div>
          <div className="modalWheel-content">{header}</div>
        </div>

        <div className="modalWheel-noti">{body}</div>
        <div className="modalWheel-gift">
          <img src={git2} alt="" className="modalWheel-gift__img" />
        </div>
        {isConfirm && (
          <div className="modalWheel__confirm">
            <div
              onClick={handleSuccess}
              className="btn btn-submit"
              style={{ fontSize: '1.2rem', minWidth: '90px' }}>
              Đồng ý
            </div>
            <div
              onClick={() => setIsShowModal(false)}
              className="btn btn-delete "
              style={{ fontSize: '1.2rem', minWidth: '90px' }}>
              Huỷ
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalConfirmSuccess;
