import { appActions } from '@redux/slice/AppSlice';
import { useDispatch } from 'react-redux';
import apiConfig from 'src/config/api.config';

export const useAxios = () => {
  const dispatch = useDispatch();
  const fetchData = async ({ url, method, body = null, error }) => {
    dispatch(appActions.startLoading());

    try {
      const data = await await apiConfig[method](url, {
        jsonData: body,
      });
      dispatch(appActions.stopLoading());
      if (!data) {
        throw new Error(error.toString());
      }
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  return { fetchData };
};
