import axios from 'axios';
import moment from 'moment';
import platform from 'platform';

export default axios.create({
  baseURL: `https://apiapps.mobifone9.vn/websaymee/v1/danhsach/`,
});
// export default axios.create({
//   baseURL: `http://localhost:8202/websaymee/v1/danhsach/`,
// });
export const jsonLog = {
  channel: 'WEB_REACT_LANGNGHE',
  imei: '',
  key: '',
  lang: 'vi',
  lat: getDeviceLat(),
  lng: getDeviceLng(),
  model: getDeviceModel(),
  os: platform.os,
  time: moment().format('YYYYMMDDHHmmss'),
  version: '1.0.0',
};

function getDeviceLat() {
  return platform.name;
}

function getDeviceLng() {
  return platform.product;
}

function getDeviceModel() {
  return platform.description;
}
