import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  loading: false,
  modal: {
    default: false,
  },
  valueShopcode: {
    value: null,
    phoneValue: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loading = false;
    },

    toggleModal(state, action) {
      const isOpenModal = state.modal[action.payload];
      if (typeof isOpenModal === 'undefined' || isOpenModal == null) {
        state.modal[action.payload] = true;
      } else {
        state.modal[action.payload] = !isOpenModal;
      }
    },
    saveUserInfo(state, action) {
      state.userInfo = action.payload.userInfo;
    },
    saveValueShopCode(state, action) {
      state.valueShopcode.value = action.payload.valueShopCode;
      state.valueShopcode.phoneValue = action.payload.phoneValue;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const appState = (state) => state.app;
export const appSelector = {
  loading: createSelector(appState, (app) => app.loading),
  modal: (name) => createSelector(appState, (app) => app.modal[name]),
  userInfo: createSelector(appState, (app) => app.userInfo),
  valueShopcode: createSelector(appState, (app) => app.valueShopcode),
};
export const appActions = appSlice.actions;
export const appReducer = appSlice.reducer;
