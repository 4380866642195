import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  percent: 0,
};

const ProgressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    plusPoints(state) {
      state.percent = state.percent + 12.5;
    },
    minusPoints(state) {
      state.percent = state.percent - 12.5;
    },
    clearPoints() {
      return initialState;
    },
  },
});

// ===========================================================
// >>>> FOR EXPORTS <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
// ===========================================================
const progressState = (state) => state.progress;
export const progressSelector = {
  percent: createSelector(progressState, (progress) => progress.percent),
};
export const progressActions = ProgressSlice.actions;
export const progressReducer = ProgressSlice.reducer;
