/* eslint-disable react-hooks/exhaustive-deps */
import { assignShopCode } from '@api/api';
import ModalConfirm from '@components/ModalConfirm/ModalConfirm';
import ModalConfirmSuccess from '@components/ModalConfirmSuccess/ModalConfirmSuccess';
import { useAxios } from '@hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getValuePhone, setIdDevice } from 'src/utils/auth';
import Success from '../../assets/images/Success.svg';
import back from '../../assets/images/back.png';
const History = () => {
  const { fetchData } = useAxios();
  const dataSelector = getValuePhone();
  console.log('dataSelector', dataSelector);
  const id = setIdDevice();
  const navigate = useNavigate();
  const [isShowModal, setIsShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [listHistory, setListHistory] = useState([]);
  const [success, setSuccess] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [error, setError] = useState({
    error: false,
    message: '',
  });
  useEffect(() => {
    history();
  }, [id]);
  const history = async () => {
    try {
      const dataBody = {
        pCung: '',
        pNoidung: '',
        pNgaysinh: '',
        pThietbi: id,
        pTrangthai: 1,
      };
      const respData = await fetchData({
        method: 'post',
        url: '/sim',
        body: dataBody,
      });
      if (respData) setListHistory(respData.data.pResult);
      console.log('@setListHistory', listHistory);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleBack = () => {
    navigate('/');
  };
  const handleDelete = async () => {
    const dataBody = {
      pSothuebao: phoneNumber,
      pThietbi: id,
    };
    if (phoneNumber) {
      try {
        const respData = await fetchData({
          method: 'post',
          url: '/delete',
          body: dataBody,
        });
        if (respData.code === '0000') {
          history();
          setIsShowModal(false);
        } else {
          setError({ error: true, message: respData.mess });
        }
      } catch (error) {}
    }
  };
  const handleShow = (phoneNumber) => () => {
    setPhoneNumber(phoneNumber);
    // handleDelete(phoneNumber);
    setIsShowModal(true);
  };
  const handleConfirm = async () => {
    const dataBody = {
      pSothuebao: phoneNumber,
      pThietbi: id,
    };
    try {
      const data = await assignShopCode(
        dataSelector.valueShopCode,
        dataSelector.phoneValue,
      );
      console.log('nhanle', data.code);
      if (data.code === '0000') {
        await fetchData({
          method: 'post',
          url: '/xacnhan',
          body: dataBody,
        });
        setConfirmSuccess(false);

        setSuccess(true);
        history();
      } else if (data.code === '9999') {
        setIsShowModal(true);
        setConfirmSuccess(false);
        setError({ error: true, message: data.mess });
      }
    } catch (error) {}
  };
  const handleShowSuccess = (phoneNumber) => () => {
    setPhoneNumber(phoneNumber);
    setConfirmSuccess(true);
  };
  return (
    <div className="his__main">
      {isShowModal && (
        <ModalConfirm
          remove
          setIsShowModal={setIsShowModal}
          header="Cảnh báo"
          body={
            error.error
              ? error.message
              : `Bạn có chắc chắn muốn xoá số điện thoại ${dataSelector?.phoneValue} không ? `
          }
          error
          handleDelete={handleDelete}
          inConfirm={error.error}
        />
      )}
      {success && (
        <ModalConfirm
          setIsShowModal={setSuccess}
          header="Thành công"
          body="Chúc mừng quý khách đã đặt hàng thành công. Nhân viên Mobifone sẽ liên hệ quý khách để hỗ trợ đăng ký sim."
        />
      )}
      {confirmSuccess && (
        <ModalConfirmSuccess
          setIsShowModal={setConfirmSuccess}
          header="Xác nhận"
          body={`Bạn có chắc chắn muốn đặt hàng số điện thoại ${dataSelector?.phoneValue} không? `}
          handleSuccess={handleConfirm}
          isConfirm
        />
      )}
      <div className="back" onClick={handleBack}>
        <img src={back} alt="" />
        <p>Quay lại</p>
      </div>
      <div className="his">
        <div className="his__container mobile">
          <h2 style={{ textAlign: 'center' }}>Giỏ hàng của bạn!</h2>
          <p>Bạn vui lòng đặt hàng để nhận sim nhé</p>
          {listHistory.map((item, index) => (
            <div key={index} className="content">
              <div className="his__success">
                {item.giuso === 1 && <img src={Success} alt="" />}
              </div>
              <div className="his__row">
                <p className="his__title">0{item.sothuebao}</p>
                <p className="his__title pink">{item.hoten}</p>
              </div>
              <div className="his__tab"></div>

              <div className="his__row">
                <p className="his__body">Trường</p>
                <p className="his__body pink">{item.truong}</p>
              </div>
              <div className="his__row">
                <p className="his__body">Lớp</p>
                <p className="his__body pink">{item.lop}</p>
              </div>
              <div className="his__row">
                <p className="his__body">Ngày thêm</p>
                <p className="his__body pink">{item.ngaygiu}</p>
              </div>
              <div className="his__confirm end">
                {item.giuso === 2 && (
                  // số 2 là chưa giữ , 1 là giữ thành công
                  <>
                    {item.giuso === 2 && (
                      <>
                        <div
                          onClick={handleShowSuccess(item.sothuebao)}
                          className="btn btn-submit"
                          style={{
                            fontSize: '1.2rem',
                            minWidth: '90px',
                            marginTop: '0',
                          }}>
                          Đặt hàng
                        </div>

                        <div
                          onClick={handleShow(item.sothuebao)}
                          className="btn btn-delete"
                          style={{
                            fontSize: '1.2rem',
                            minWidth: '90px',
                          }}>
                          Xoá
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="his__container laptop">
          <h2 style={{ textAlign: 'center' }}>Giỏ hàng của bạn!</h2>

          <div className="his__table">
            <table>
              <thead>
                <tr>
                  <td>STT</td>
                  <td>Số điện thoại</td>
                  <td>Cung hoàng dạo</td>
                  <td>Họ tên</td>
                  <td>Trường</td>
                  <td>Lớp</td>
                  <td>Ngày thêm</td>

                  <td></td>
                </tr>
              </thead>
              <tbody>
                {listHistory.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>0{item.sothuebao}</td>
                      <td>{item.tencung}</td>
                      <td>{item.hoten}</td>
                      <td>{item.truong}</td>
                      <td>{item.lop}</td>
                      <td>{item.ngaygiu}</td>
                      <td>
                        <div className="his__confirm">
                          {item.giuso === 2 ? (
                            // số 2 là chưa giữ , 1 là giữ thành công
                            <>
                              {item.giuso === 2 && (
                                <>
                                  <div
                                    onClick={handleShowSuccess(item.sothuebao)}
                                    className="btn btn-submit"
                                    style={{
                                      fontSize: '1.2rem',
                                      minWidth: '90px',
                                    }}>
                                    Đặt hàng
                                  </div>

                                  <div
                                    onClick={handleShow(item.sothuebao)}
                                    className="btn btn-delete"
                                    style={{
                                      fontSize: '1.2rem',
                                      minWidth: '90px',
                                    }}>
                                    Xoá
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <img src={Success} alt="" />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
